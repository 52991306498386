import React, { Component } from 'react';

const configurationManager = require('../../../utils/config/configurationManager');

class MainPortalBanner extends Component
{
    render()
    {
        return  <div
                    style={{ 
                        paddingLeft: "30px", 
                        paddingRight: "30px", 
                        paddingTop: "25px", 
                        paddingBottom: "25px", 
                        background: '#FDF2CD', 
                        borderWidth: "3px", 
                        borderColor: "#B2B0A6",
                        borderStyle: "solid",
                        borderRadius: "10px"
                    }}>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "black" }}>
                        <p style={{marginBottom:'0.5rem'}}> 
                            <b>Update: </b>
                            All services in this portal can now be configured in the main Flipdish Portal. This portal will be decommissioned by {configurationManager.getConfig().modulesMainPortalShiftingDate}. 
                            <br/>
                        </p>
                        <p className='mb-0'>
                            <a href="https://www.flipdish.com/ie/resources/blog/flipdish-portal-update" 
                            target="_blank" 
                            rel="noopener noreferrer">
                                <b>Click here to find out more information</b>
                            </a>. 
                            To go to the main Flipdish portal,&nbsp;
                            <a href={configurationManager.getConfig().mainPortalURL} 
                            target="_blank" 
                            rel="noopener noreferrer">
                                <b>click here</b>
                            </a>.
                        </p>
                    </div>
                </div>
    }
}

export default MainPortalBanner;