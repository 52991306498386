
const configurationManager = require('../../utils/config/configurationManager');
const securityConstants = require('../../utils/domain/security/securityConstants');

module.exports.options = 
[
    {
        name: "reports",
        title: "Reports",
        options:
        [
            {
                title: "Sales",
                description: "Sales BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.icon.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/reports/dashboards?tab=rms",
            },

            {
                title: "Costs",
                description: "Costs BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.costs.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/reports/dashboards?tab=rms",
            },

            {
                title: "Payroll",
                description: "Payroll BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payroll.icon.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/reports/dashboards?tab=rms",
            },

            {
                title: "Inventory",
                description: "Inventory BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.icon.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/reports/dashboards?tab=rms",
            },

            /*{
                title: "Marketing",
                description: "Marketing Analytics",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/marketing.icon.png`,

                target: "reports.marketing",
                actionType: "menu"
            },

            {
                title: "Accounts",
                description: "Accounts BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.icon.png`,

                target: "reports.accounts",
                actionType: "menu"
            },
            */

            

            {
                title: "Menu",
                description: "Menu BI Tools",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/menu.icon.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/reports/dashboards?tab=rms",
            },

            /*{
                title: "Todays Status Report",
                description: "Todays Status Report",
                meta: "Todays Report, Today, Status",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.live.status.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.TodaysStatusReport",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            }*/
        ]
    },

    {
        name: "reports.sales",
        title: "Sales Reports",
        options:
        [
            {
                title: "Sales Overview",
                description: "Periodic Sales Overview Analytics",
                meta: "Summary, Overview, Analytics, Sales, Venue Sales, Payment Methods, Discounts, Refunds",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.overview.analytics.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Reports.Accounts.Sales.SalesOverviewAnalytics",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales %VAT%",
                description: "Sales %VAT%",
                meta: "Sales, %VAT%, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.vat.analytics.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Reports.Accounts.SalesVatAnalytics",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "End Of Day Report",
                description: "Daily Float Sessions Summary Report",
                meta: "Daily, Summary, Float",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.vat.analytics.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "Reports.Accounts.FloatSessions.DailySummaryReport",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales Discounts",
                description: "Sales Discounts",
                meta: "Sales Discounts, Discounts, Loss, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/discount.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailyDiscounts",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales Refunds",
                description: "Sales Refunds",
                meta: "Sales Refunds, Refunds, Loss, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sales.refund.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailyRefunds",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales Charges",
                description: "Sales Charges",
                meta: "Sales Charges, Charges, Loss, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sales.charge.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailyCharges",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Deposit Transaction Report",
                description: "Deposit Transactions Report",
                meta: "Deposit, Return, Charge, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.deposit.return.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "Reports.Accounts.FloatSessions.DepositReturnReport",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Hourly Sales",
                description: "Sales By Hour",
                meta: "Hourly Sales, Hourly Sale",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.sales.icon.png`, 

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.HourlySales",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Kitchen Timings Report",
                description: "Kitchen Prep, Dispatch and Delivery Timing Analytics",
                meta: "Kitchen, Prep, Dispatch, Delivery, Analytics",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.timing.analytics.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "Reports.Accounts.Sales.SaleTimingAnalytics",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },
            
            /*{
                title: "Sales Overview Report",
                description: "Periodic Sales Overview Report",
                meta: "Summary, Overview, Sales, Venue Sales, Payment Methods, Discounts, Refunds",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.summary.bi.report.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.PeriodicSummaryReport",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Sales Summary",
                description: "Sales Summary",
                meta: "Sales Summary, Revenue, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.daily.sales.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailySales",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Venue",
                description: "Sales Summary By Venue",
                meta: "Sales, Revenue, Sales, Venue",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.venue.icon.v2.png`,  

                orgLevel: "franchisor, property",
                actionType: "component",            
                targetComponent: "BI.Tools.Sales.DailySalesByVenue",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Payment Method",
                description: "Sales Summary By Payment Method",
                meta: "Daily Sales, Revenue, Sales, Payment, Method",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.payment.icon.png`,     

                orgLevel: "franchisor, property",
                actionType: "component",            
                targetComponent: "BI.Tools.Sales.DailySalesByPaymentMethod",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Hourly Sales",
                description: "Sales By Hour",
                meta: "Hourly Sales, Hourly Sale",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.sales.icon.png`, 

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.HourlySales",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Table Turnover",
                description: "Sales Summary By Tables",
                meta: "Daily Sales, Revenue, Sales, Tables, Method",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.table.turn.over.png`,   

                orgLevel: "franchisor, property",
                actionType: "component",            
                targetComponent: "BI.Tools.Sales.DailySalesByTable",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Hourly Table Turnover",
                description: "Hourly Sales Summary By Tables",
                meta: "Hourly Sales, Revenue, Sales, Tables, Method",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.table.turn.over.png`,

                orgLevel: "franchisor, property",
                actionType: "component",            
                targetComponent: "BI.Tools.Sales.HourlySalesByTable",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Sales Vs Projections",
                description: "Projections Vs Actual Sales Comparison",
                meta: "Sales Summary, Revenue, Sales, Projection",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.projection.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.Projection.BISalesVsProjections",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Customer Retention",
                description: "Customer Retention",
                meta: "Customer Retention, Customer, Retention",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/customer.retention.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailyCustomerRetention",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_marketing", "property_admin", "property_accounts", "property_marketing"]
            },

            {
                title: "Mileage",
                description: "Daily sales by venue and mileage",
                meta: "Sales by Mileage, Mileage, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/sales.mileage.bi.report.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Sales.DailySalesByVenueAndMileage",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_marketing", "property_admin", "property_accounts", "property_marketing"]
            },*/

        ]
    },

    /*{
        name: "reports.marketing",
        title: "Marketing Analytics",
        options:
            [
                {
                    title: "Customer Sales Analytics",
                    description: "Customer sales analytics over past six months",
                    meta: "Marketing, Customers, Sales",
                    imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/customers.icon.png`,

                    orgLevel: "franchisor",
                    actionType: "component",
                    targetComponent: "Reports.Marketing.CustomerAnalytics",
                    writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts"]
                },
            ]
    },
    
    {
        name: "reports.accounts",
        title: "Accounts Reports",
        options:
        [
            {
                title: "Accounts Overview Report",
                description: "Periodic Accounts Overview Report",
                meta: "Consolidated, Accounts, Sales, Wages, COGS, Discrepancies",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.consolidated.bi.report.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.PeriodicConsolidatedReport",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Sales Royalty",
                description: "Sales Royalty",
                meta: "Royalty, Sales, Charges",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.royalty.icon.png`, 

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailySalesRoyalty",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Sales Vs Costs",
                description: "Sales Vs Costs",
                meta: "Sales, Costs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.wages.cost.goods.icon.png`,       

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailySalesVsCosts",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            {
                title: "Sales %VAT% Summary",
                description: "Sales %VAT%",
                meta: "Sales, %VAT%, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.vat.payable.vat.icon.png`,      

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailySalesVAT",
                writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "property_admin", "property_accounts"]
            },

            
        ]
    },*/

    

    {
        name: "reports.costs",
        title: "Costs Reports",
        options:
        [
            {
                title: "Costs Overview Report",
                description: "Periodic Costs Summary Report",
                meta: "Costs, Sales, Wages, COGS, Discrepancies",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.consolidated.costs.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.Costs.Periodic.Summary.Report",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Wages Costs",
                description: "Payroll Wages Costs",
                meta: "Payroll, Costs, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.wages.costs.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Payroll.DailyPayrollCosts",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Venue Commissions",
                description: "Venue Commissions",
                meta: "Venue, Commission, Charges",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.venue.icon.v2.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailyVenueCommissions",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Cogs Summary",
                description: "Daily Cogs Summary",
                meta: "Inventory, Cogs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.cost.goods.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.CogsSummary",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Fixed Costs",
                description: "Fixed Costs",
                meta: "Fixed, Costs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/fixed.cost.icon.png`,
        

                orgLevel: "franchisor, property",
                actionType: "component",            
                targetComponent: "BI.Tools.Accounts.BIFixedCosts",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Staff Meals",
                description: "Daily Staff Meals",
                meta: "Sales, Report, Staff",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.staff.meals.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Payroll.DailyStaffMeals",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Guest Meals",
                description: "Daily Guest Meals",
                meta: "Sales, Report, Guest",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.guest.meals.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailyGuestMeals",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Freebies Meals",
                description: "Daily Freebies Meals",
                meta: "Sales, Report, Freebies",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.freebies.meals.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Accounts.DailyFreebiesMeals",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

        ]
    },

    {
        name: "reports.payroll",
        title: "Payroll Reports",
        options:
        [
            {
                title: "Daily Payroll Costs",
                description: "Daily Payroll Costs",
                meta: "Payroll, Costs, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.wages.costs.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Payroll.DailyPayrollCosts",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales By Driver",
                description: "Daily Sales By Driver",
                meta: "Driver, Sales, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.driver.sales.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Payroll.DailySalesByDriver",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Upselling Points",
                description: "Upselling Points",
                meta: "Upselling Points, Report",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.staff.upselling.points.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Payroll.DailyUpsellingPoints",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },


        ]
    },

    {
        name: "reports.inventory",
        title: "Inventory Reports",
        options:
        [
            {
                title: "Purchase Orders",
                description: "Inventory Stock Purchase Order Receipts",
                meta: "Inventory, Purchase Orders, Stock Items",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.inventory.purchases.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.InventoryPurchaseReport",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Wastages",
                description: "Inventory Wastages",
                meta: "Wastages, Stock Items, Products",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.wastage.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.WastagesReport",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Discrepancies",
                description: "Inventory Stock Taking Discrepancies",
                meta: "Inventory, Discrepancies",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.inventory.discrepancies.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.DailyDiscrepancies",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Inventory Status",
                description: "Inventory status by date range",
                meta: "Stock Items, Inventory",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.inventory.current.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.InventoryStatusReport",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Cogs Summary",
                description: "Daily Cogs Summary",
                meta: "Inventory, Cogs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.cost.goods.icon.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.CogsSummary",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales By Product",
                description: "Retail and Cost Price By Product Sales",
                meta: "Sales Product, Product, Sales",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.product.icon.v2.png`,

                orgLevel: "property",
                actionType: "component",
                targetComponent: "BI.Tools.Inventory.DailySalesByProduct",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },
        ]
    },

    {
        name: "reports.menu",
        title: "Menu Reports",
        options:
        [
            {
                title: "Menu Item Sales",
                description: "Sales by Menu Items",
                meta: "Menu Item Sales, Menu, Analysis",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.revenue.comparison.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Menu.Item.Sales",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Hourly Menu Item Sales",
                description: "Hourly Sales by Menu Items",
                meta: "Menu Item Sales, Menu, Analysis",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.menu.item.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Hourly.Menu.Item.Sales",
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Reporting Tag Sales",
                description: "Sales by Menu Item Reporting Tags",
                meta: "Reporting Tag Sales, Tags, Reporting, Analysis",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.daily.reporting.tag.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Menu.Daily.Tags.Sales",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Hourly Reporting Tag Sales",
                description: "Hourly Sales By Reporting Tags",
                meta: "Reporting Tag Sales, Tags, Reporting, Analysis",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.reporting.tag.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Menu.Hourly.Tags.Sales",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Menu Item Refunds",
                description: "Refunds By Menu Item",
                meta: "Menu Item Refunds, Refunds, Loss",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/menu.item.refund.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "BI.Tools.Menu.DailyMenuItemRefunds",
                readRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            // {
            //     title: "Menu Item Sales (Legacy)",
            //     description: "Menu Item Sales (Legacy)",
            //     meta: "Menu Item Sales, Menu, Analysis",
            //     imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.revenue.comparison.icon.png`,

            //     orgLevel: "franchisor, property",
            //     actionType: "component",
            //     targetComponent: "BI.Tools.Menu.Item.Sales.Legacy",
            //     writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_product", "property_admin", "property_accounts", "property_product"]
            // },

            // {
            //     title: "Daily Reporting Tag Sales (Legacy)",
            //     description: "Daily Reporting Tag Sales (Legacy)",
            //     meta: "Reporting Tag Sales, Tags, Reporting, Analysis",
            //     imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.daily.reporting.tag.png`,

            //     orgLevel: "franchisor, property",
            //     actionType: "component",
            //     targetComponent: "BI.Tools.Menu.Daily.Tags.Sales.Legacy",
            //     writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_product", "property_admin", "property_accounts", "property_product"]
            // },

            // {
            //     title: "Hourly Reporting Tag Sales (Legacy)",
            //     description: "Hourly Reporting Tag Sales (Legacy)",
            //     meta: "Reporting Tag Sales, Tags, Reporting, Analysis",
            //     imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.hourly.reporting.tag.png`,

            //     orgLevel: "franchisor, property",
            //     actionType: "component",
            //     targetComponent: "BI.Tools.Menu.Hourly.Tags.Sales.Legacy",
            //     writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_product", "property_admin", "property_accounts", "property_product"]
            // },

            // {
            //     title: "Menu Item Refunds (Legacy)",
            //     description: "Menu Item Refunds (Legacy)",
            //     meta: "Menu Item Refunds, Refunds, Loss",
            //     imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/menu.item.refund.icon.png`,

            //     orgLevel: "franchisor, property",
            //     actionType: "component",
            //     targetComponent: "BI.Tools.Menu.DailyMenuItemRefunds.Legacy",
            //     writeRoles:["system_admin", "support_admin", "support", "franchisor_admin", "franchisor_accounts", "franchisor_product", "property_admin", "property_accounts", "property_product"]
            // },
        ]
    },

    {
        name: "modules",
        title: "Modules",
        options:
        [
            {
                title: "Sales",
                description: "Manage sales and sales adjustments",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sales.icon.png`,

                target: "modules.sales",
                actionType: "menu"
            },

            {
                title: "Inventory",
                description: "Manage inventory and purchase orders",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.icon.png`,

                target: "modules.inventory",
                actionType: "menu"
            },

            {
                title: "Central Kitchen",
                description: "Manage Central Kitchen",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/central.kitchen.icon.png`,

                subscription: 'Central Kitchen',
                orgLevel: "property",
                actionType: "menuGenerator",
                actionParameter: { menuType: "modules.central.kitchen", waitTitle:"Please wait, loading central kitchen configurations options" },
            },

            {
                title: "Marketing",
                description: "Manage customers, discount offers, vouchers, gift cards and marketing campaigns",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/marketing.icon.png`,

                target: "modules.marketing",
                actionType: "menu"
            },

            {
                title: "Payroll",
                description: "Manage staff, rota, payroll and vacations",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payroll.icon.png`,

                target: "modules.payroll",
                actionType: "menu"
            },

            {
                title: "Food Safety",
                description: "Manage tasks, labelling and temperature logging",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.icon.png`,

                target: "modules.food.safety",
                actionType: "menu"
            },

            {
                title: "Accounts",
                description: "Manage expenses and recurring bills",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.icon.png`,

                target: "modules.accounts",
                actionType: "menu"
            },

            {
                title: "Documents Explorer",
                description: "Manage online drive to store documents",
                meta: "Document, Documents, Drive, Drives, Storage, Disk, Explorer, Upload, Download",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/documents.explorer.icon.png`,

                subscription: 'Document Explorer',
                actionType: "component",
                targetComponent: "DocumentsExplorer",
                orgLevel: "franchisor, property",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Service Desk",
                description: "Service Desk Tasks",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/security.icon.png`,

                target: "module.service.desk",
                actionType: "menu"
            },

            {
                title: "Security",
                description: "Manage user roles",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/security.icon.png`,

                target: "modules.security",
                actionType: "menu"
            },
            
            {
                title: "Audit Logs",
                description: "Sequential records for monitoring.",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.logs.viewer.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor,property",
                actionType: "component",
                targetComponent: "AuditLogs",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },
        ]
    },

    {
        name: "modules.sales",
        title: "Sales Modules",
        options:
        [
            {
                title: "View Sales",
                description: "View sales",
                meta: "Sales, Sale",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sales.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "ViewSales",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "View Cancelled Sales",
                description: "View cancelled sales",
                meta: "Sales, Sale, Cancelled",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sales.cancel.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "ViewCancelledSales",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Sales Projections",
                description: "Manage sales projections and overrides",
                meta: "Sales, Sale, Override, Overrides, Projection, Projections",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.projection.override.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "SalesProjectionOverride",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Storefront Commissions",
                description: "Manage storefront commissions",
                meta: "Storefront, Storefronts, Commission, Commissions",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/report.sales.venue.icon.v2.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "StorefrontCommissionRuns",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Driver Sales",
                description: "Manage delivery drivers for sales",
                meta: "Sale, Sales, Driver, Drivers, Rider, Riders",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/driver.sales.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "DriverSales",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },
        ]
    },

    {
        name: "modules.inventory",
        title: "Inventory Modules",
        options:
        [
            {
                title: "Purchase Order",
                description: "Manage purchase orders",
                meta: "Purchase, Order, Orders, Inventory, Approve",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/purchase.order.icon.png`,

                subscription: 'Inventory',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "PurchaseOrders",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Stock Items",
                description: "Manage stock items",
                meta: "Inventory, Stock Items, Stock Item",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.item.icon.png`,

                subscription: 'Inventory',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "StockItems",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner],
            },

            {
                title: "Stock Item Categories",
                description: "Manage stock item categories",
                meta: "Inventory, Stock Items, Stock Item, Category, Categories",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.category.icon.png`,

                subscription: 'Inventory',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "StockItemCategories",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner],
            },

            

            {
                title: "Supplier",
                description: "Manage suppliers",
                meta: "Supplier, Suppliers",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/inventory.supplier.icon.png`,

                subscription: 'Inventory',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Suppliers",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner],
            },

            {
                title: "Tracking Profile",
                description: "Manage tracking profiles",
                meta: "Profile, Track, Stock Item, Inventory",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/purchase.order.icon.png`,

                subscription: 'Inventory',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "InventoryTrackingProfiles",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner],
            },

        ]
    },

    {
        name: "modules.payroll",
        title: "Payroll Modules",
        options:
        [
            {
                title: "Staff",
                description: "Manage staff",
                meta: "Staff, Pin",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payroll.staff.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Staff",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Rota",
                description: "Manage rota and attendance",
                meta: "Rota, Attendance",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/rota.icon.png`,

                subscription: 'Payroll',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Rota",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Holiday Calendar",
                description: "Manage holidays",
                meta: "Holiday, Vacation, Calendar",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/holidays.icon.png`,

                subscription: 'Payroll',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "HolidayPlanner",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Payroll Calculator",
                description: "Payroll Calculator",
                meta: "Holiday, Vacation, Calendar",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payroll.calculator.png`,
        

                subscription: 'Payroll',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "PayrollRun",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },
            {
                title: "Staff Role",
                description: "Define Staff Roles",
                meta: "",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payroll.staff.role.icon.png`,
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "StaffRole",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            }

        ]
    },

    {
        name: "modules.food.safety",
        title: "FoodSafety Modules",
        options:
        [
            {
                title: "Task Logs",
                description: "Food Safety Task Logs",
                meta: "Task Logs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.tasks.icon.png`,

                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "TaskLogs",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Stock Cooking Temperature Logs",
                description: "View cooking temperature logs for stock items",
                meta: "FoodSafety, Safety, Stock Items, Cooking, Temperature, Logs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/stock.items.temperature.logs.icon.png`,

                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "StockItemCookingTemperatureLogs",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Stock Storage Temperature Logs",
                description: "View storage temperature logs for stock items",
                meta: "FoodSafety, Safety, Stock Items, Storage, Temperature, Logs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/stock.items.storage.logs.icon.png`,

                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "StockItemStorageTemperatureLogs",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Appliance Temperature Logs",
                description: "View temperature logs for stock items",
                meta: "FoodSafety, Safety, Appliances, Temperature, Logs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/appliances.temperature.logs.icon.png`,
        
                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "ApplianceTemperatureLogs",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Incident Logs",
                description: "Food Safety Incident Logs",
                meta: "Incident Logs",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.incident.logs.icon.png`,
        

                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "IncidentLogs",
                readRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Appliances",
                description: "Manage appliances for foodsafety and regulatory compliance",
                meta: "FoodSafety, Safety, Appliances",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.appliances.icon.png`,
        
                subscription: 'Food Safety',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Appliances",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Task Types",
                description: "Manage task types for foodsafety and regulatory compliance",
                meta: "FoodSafety, Safety, Tasks, Task Types",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.task.types.icon.png`,
            
        
                subscription: 'Food Safety',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "TaskTypes",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Tasks",
                description: "Manage tasks for foodsafety and regulatory compliance",
                meta: "FoodSafety, Safety, Tasks",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.tasks.icon.png`,
            
        
                subscription: 'Food Safety',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Tasks",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Incident Types",
                description: "Manage incident types",
                meta: "FoodSafety, Safety, Incident, Types",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/foodsafety.incident.types.icon.png`,
            
        
                subscription: 'Food Safety',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "IncidentTypes",
                readRoles: [securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

        ]
    },

    {
        name: "modules.marketing",
        title: "Marketing Modules",
        options:
        [
            {
                title: "Customers",
                description: "Manage customers",
                meta: "Customers, Customer",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/customers.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Customers",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Import Customers",
                description: "Import customers",
                meta: "Customers, Customer",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/customers.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor",
                actionType: "component",
                targetComponent: "Import.Customers",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated]
            },

            {
                title: "Discount Offers",
                description: "Manage discount offers",
                meta: "Discount, Discounts",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/discount.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "DiscountOffers",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Vouchers",
                description: "Manage vouchers",
                meta: "Voucher, Vouchers",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/voucher.codes.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "VoucherCodes",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Gift Cards",
                description: "Manage gift cards",
                meta: "Gift Cards, Gift Card, Payment Method, Payment Methods",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payment.types.icon.png`,
        

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Campaign",
                description: "Manage marketing campaign",
                meta: "campaign, campaigns, ads, advertisements, sms",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/marketing.campaign.icon.png`,
        

                subscription: 'Marketing',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Campaigns",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Signage",
                description: "Manage Signage",
                meta: "ads, advertisements, customer display, signage",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/signage1.icon.png`,
        
                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "SignagePlayers",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "KIOSK Skin",
                description: "Manage KIOSK skin",
                meta: "ads, advertisements, customer display, signage, kiosk",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/kiosk.png`,
        

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "KioskSkins",
                readRoles: [securityConstants.roleCodes.FinanceManager],
                writeRoles: [securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

        ]
    },

    {
        name: "modules.accounts",
        title: "Accounts Modules",
        options:
        [
            {
                title: "Expenses",
                description: "Manage Expenses",
                meta: "Accounts, Expenses, Bills",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.expenses.icon.png`,

                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Expenses",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Bills",
                description: "Manage Bills",
                meta: "Accounts, Expenses, Recurring, Bills",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/account.bills.icon.png`,

                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Bills",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Credit Accounts Status",
                description: "Manage credit accounts status",
                meta: "Account, Accounts, Credit Book, Credit Account",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sub.credit.accounts.icon.png`,

                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "CreditAccountsStatus",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Account Category",
                description: "Manage account categories",
                meta: "Account, Accounts, Category, Categories",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.category.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "AccountCategories",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Account Sub Category",
                description: "Manage account sub categories",
                meta: "Account, Accounts, Category, Categories, Sub Category, Sub Categories",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sub.category.icon.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "AccountSubCategories",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Transaction Accounts",
                description: "Manage transaction accounts",
                meta: "Account, Accounts, Transaction, Transactions",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.transactionAccounts.icon.png`,

                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "TransactionAccounts",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager]
            },

            {
                title: "Credit Books",
                description: "Manage property credit books",
                meta: "Account, Accounts, Credit Book, Credit Account",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.credit.books.icon.png`,


                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "CreditBooks",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },

            {
                title: "Credit Accounts",
                description: "Manage property credit accounts",
                meta: "Account, Accounts, Credit Book, Credit Account",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/accounts.sub.credit.accounts.icon.png`,

                subscription: 'Accounts',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "CreditAccounts",
                readRoles: [securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.FinanceManager]
            },
        ]
    },

    {
        name: "module.service.desk",
        title: "Service Desk",
        options:
        [
            {
                title: "Request Permission",
                description: "Request Role Permission",
                meta: "Request, Permissions",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,
                
        
                orgLevel: "system",
                actionType: "component",
                targetComponent: "RequestPermissions",
                writeRoles:[securityConstants.roleCodes.Support]        
            },
        
            {
                title: "Approve Permissions",
                description: "Approve role requests",
                meta: "Approve, Permissions",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.users.icon.png`,
                
                
                orgLevel: "system",
                actionType: "component",
                targetComponent: "ApprovePermissions",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin]        
            },
        ]
    },

    {
        name: "configurations",
        options:
        [
            {
                title: "System",
                description: "Manage allergies, nutrition, packaging types, wastage reasons",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.icon.png`,

                target: "configurations.system",
                actionType: "menu"
            },

            {
                title: "Printing Templates",
                description: "Manage printing templates",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/printer_template.png`,

                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/sales-channels/printing-templates",
            },

            {
                title: "Menus",
                description: "Manage menus",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/menu.icon.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                targetComponent: "Menus",
                readRoles: [securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.MenuSupport, securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner]
            },

            {
                title: "Organization",
                description: "Manage organization",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/org.icon.png`,

                target: "configurations.organization",
                actionType: "menu"
            },

            {
                title: "Stations",
                description: "Manage stations (POS, KDS, KIOSK)",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/shell.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "StationsDesigner",
                actionParameter: { menuType: "stations", waitTitle:"Please wait, loading stations" },
                readRoles: [securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated]
            },
            {
                title: "Network Diagram",
                description: "Station network diagram",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/shell.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "NetworkDiagram",
                readRoles: [securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated]
            },
            {
                title: "Central Kitchen",
                description: "Manage Central Kitchen",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/shell.png`,

                subscription: 'Central Kitchen',
                orgLevel: "property",
                actionType: "menuGenerator",
                actionParameter: { menuType: "configure.central.kitchen", waitTitle:"Please wait, loading central kitchen configurations options" },
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },
        ]
    },

    {
        name: "configurations.system",
        title: "Configuration System",
        options:
        [
            {
                title: "Allergies",
                description: "Manage allergy lookup",
                meta: "Lookup, Lookups, Allergy",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,

                subscription: 'POS',
                orgLevel: "system",
                actionType: "component",
                targetComponent: "LookupAllergies",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Nutrition",
                description: "Manage nutrition lookup",
                meta: "Lookup, Lookups, Nutrition, Nutritions",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,

                subscription: 'POS',
                orgLevel: "system",
                actionType: "component",
                targetComponent: "LookupNutritions",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Wastage Reasons",
                description: "Manage wastage reasons lookup",
                meta: "Lookup, Lookups, Reasons, Wastage",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,

                subscription: 'POS',
                orgLevel: "system",
                actionType: "component",
                targetComponent: "LookupWastageReasons",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Packaging Types",
                description: "Manage packaging types lookup",
                meta: "Lookup, Lookups, packaging, package, packages, type, types",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,

                subscription: 'POS',
                orgLevel: "system",
                actionType: "component",
                targetComponent: "LookupPackageTypes",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Venues",
                description: "Manage venues lookup",
                meta: "Lookup, Lookups, Venue, Venues",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/venue.icon.png`,

                subscription: 'POS',
                orgLevel: "system",
                actionType: "component",
                targetComponent: "Venues",
                writeRoles:[securityConstants.roleCodes.SystemAdmin]
            },


            {
                title: "Tags",
                description: "Manage tags",
                meta: "Lookup, Lookups, tag, tags",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.roles.icon.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/sales-channels/point-of-sale?tab=settings",

                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },


            {
                title: "Kitchen Station Profiles",
                description: "Manage kitchen station profiles",
                meta: "Kitchen, Station, Stations, Profile, Profiles",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/kitchen.profile.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation/kitchen-station-profiles",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },


            {
                title: "Guests",
                description: "Manage Guests",
                meta: "Guest, Guests",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/guests.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/sales-channels/point-of-sale?tab=settings",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Freebie Reasons",
                description: "Manage Freebie Reasons",
                meta: "Freebie, Reasons",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/freebie.reasons.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/sales-channels/point-of-sale?tab=settings",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },


            {
                title: "Price Bands",
                description: "Manage price bands",
                meta: "Price, Band, Bands",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/org.price.band.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/sales-channels/point-of-sale/price-bands",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },


            {
                title: "Nomenclatures",
                description: "Manage Nomenclatures",
                meta: "Nomenclatures, Nomenclature",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/nomenclatures.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation/terminology",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },


            {
                title: "Address Mapping",
                description: "Manage area postal code and area name address mappings",
                meta: "Lookup, Address Management, Address Mapping",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/lookups.addressMapping.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "AddressMapping",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Payment Methods",
                description: "Add new payment methods",
                meta: "",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/payment.methods.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/sales-channels/point-of-sale/payment-methods",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

        ]
    },

    {
        name: "configurations.printing.templates",
        title: "Configuration Printing Templates",
        options:
        [
            {
                title: "Receipt Templates",
                description: "Manage Receipt Templates",
                meta: "Print, Templates, Receipt",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.lookup.icon.png`,

                subscription: 'POS',
                actionType: "component",
                targetComponent: "Printing.Template.Receipt",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Kitchen Station Templates",
                description: "Manage Kitchen Station Printing Templates",
                meta: "Print, Templates, Receipt",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.lookup.icon.png`,

                subscription: 'POS',
                actionType: "component",
                targetComponent: "Printing.Template.KitchenStation",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Voucher Templates",
                description: "Manage Voucher Printing Templates",
                meta: "Print, Templates, Voucher",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.lookup.icon.png`,

                subscription: 'POS',
                actionType: "component",
                targetComponent: "Printing.Template.Voucher",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Daily Summary Report Templates",
                description: "Manage Daily Summary Report Printing Templates",
                meta: "Print, Templates, Receipt",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/system.lookup.icon.png`,

                subscription: 'POS',
                actionType: "component",
                targetComponent: "Printing.Template.DailySummaryReport",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

        ]
    },

    {
        name: "configurations.organization",
        title: "Configuration Organization",
        options:
        [
            {
                title: "Basic Configuration",
                description: "Configure franchisor basic configuration",
                meta: "configure, basic, Franchisor",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/franchisor.basic.configuration.png`,
                
                orgLevel: "franchisor",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Operating Configuration",
                description: "Configure operating configuration",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/franchisor.operating.configuration.png`,
                
                orgLevel: "franchisor",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "DB Configuration",
                description: "Configure database configuration",
                meta: "configure, database",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/franchisor.database.configuration.png`,
                
                orgLevel: "franchisor",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                writeRoles:[securityConstants.roleCodes.SystemAdmin]
            },

            {
                title: "API Integration",
                description: "Configure API integration",
                meta: "api, integration",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/franchisor.api.integration.png`,
                
                orgLevel: "franchisor",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                writeRoles:[securityConstants.roleCodes.SystemAdmin]
            },

            {
                title: "Add Property",
                description: "Add property",
                meta: "add, Property",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/org.property.icon.png`,

                orgLevel: "franchisor",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/properties",
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated]
            },

            {
                title: "Storefronts",
                description: "Configure property storefronts",
                meta: "configure, operating",
                imageUrl:  `app/back.office/menu/${configurationManager.getConfig().themeVersion}/storefront.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "menuGenerator",
                actionParameter: { menuType: "storefronts", waitTitle:"Please wait, loading storefronts" },
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "License Subscriptions",
                description: "License subscriptions",
                meta: "license, Property, subscription",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/org.property.icon.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.PropertyLicenseSubscriptions",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Basic Configuration",
                description: "Configure property basic configuration",
                meta: "configure, basic, property",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.basic.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.BasicConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager, securityConstants.roleCodes.Support],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.SupportElevated, securityConstants.roleCodes.Owner]
            },

            {
                title: "Operating Configuration",
                description: "Configure property operating configuration",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.operating.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.OperatingConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Payroll Configuration",
                description: "Configure property payroll configuration",
                meta: "configure, payroll, property",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.payroll.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.PayrollConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Layout Configuration",
                description: "Configure property layout configuration",
                meta: "configure, layout",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.operating.configuration.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.LayoutConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Staff Permissions Configuration",
                description: "Configure Property Staff Permissions Configuration",
                meta: "configure, staff, permission",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.staff.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.StaffPermissionsConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Print Configuration",
                description: "Configure property printing configuration",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.print.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.PrintConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Geo Configuration",
                description: "Configure property geo configuration",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.geo.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.GeoConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Float Configuration",
                description: "Configure property float configuration",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.float.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.FloatConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Address Configurations",
                description: "Configure property address configurations",
                meta: "configure, operating",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.address.configuration.png`,
                
                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.AddressConfigurations",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Delivery Management Configuration",
                description: "Configure property delivery management configuration",
                meta: "configure, delivery",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.delivery.management.configuration.png`,

                subscription: 'POS',
                orgLevel: "property",
                actionType: "component",
                targetComponent: "Organization.Property.DeliveryManagementConfiguration",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Localization Configuration",
                description: "Configure localization setting overrides",
                meta: "configure, localization",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/localization.configuration.png`,

                subscription: 'POS',
                orgLevel: "franchisor, property",
                isDisabled: true,
                actionType: "component",
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Charges Configuration",
                description: "Configure charges",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.service.charges.configuration.png`,

                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/organisation",
                readRoles: [securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support, securityConstants.roleCodes.Owner]
            },

            {
                title: "Brands",
                description: "Configure brands",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.delivery.management.configuration.png`,
                
                orgLevel: "franchisor, property",
                actionType: "component",
                isDisabled: true,
                targetComponent: "Redirection",
                targetUrl: "[brandId]/settings/brands",
                readRoles: [securityConstants.roleCodes.Owner, securityConstants.roleCodes.ManagedOwner, securityConstants.roleCodes.StoreOwner, securityConstants.roleCodes.FinanceManager, securityConstants.roleCodes.StoreManager],
                writeRoles:[securityConstants.roleCodes.SystemAdmin, securityConstants.roleCodes.SupportAdmin, securityConstants.roleCodes.Support]
            },    
        ]
    },

    {
        name: "settings",
        options:
        [
            {
                title: "Switch Account",
                description: "Switch to different account",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/property.external.app.icon.png`,

                actionType: "action",
                actionId: "switch"
            },

            {
                title: "LogOut",
                description: "Logout from system",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/logout.icon.png`,

                actionType: "action",
                actionId: "logout"
            },

            {
                title: "Change Password",
                description: "Change your current password",
                imageUrl: `app/back.office/menu/${configurationManager.getConfig().themeVersion}/change.password.png`,

                actionType: "component",
                targetComponent: "ChangePassword",
            },
        ]
    },

]